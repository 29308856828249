const UTAG_SCRIPT_TAG_ID = 'utag-signiniw';
const DATA_ATTR_VERSION = 'version';
const DATA_ATTR_MEDIAID = 'mediaid';
const DATA_ATTR_LOGIN_STATUS = 'loginstatus';
const DATA_ATTR_URL = 'utagUrl';

const initUtag = (src) => {
  const tag = 'script';
  const firstScriptElement = document.getElementsByTagName(tag)[0];
  // create utag script element
  let utagScriptElement = document.createElement(tag);
  utagScriptElement.src = src;
  utagScriptElement.type = 'text/javascript';
  utagScriptElement.async = true;
  // insert utag script before first script.
  firstScriptElement.parentNode.insertBefore(utagScriptElement, firstScriptElement);
};

document.addEventListener('DOMContentLoaded', function () {
  const utagScriptElement = document.getElementById(UTAG_SCRIPT_TAG_ID);
  const utagSrcUrl = utagScriptElement.dataset[DATA_ATTR_URL];

  const appVersion = utagScriptElement.dataset[DATA_ATTR_VERSION] || 'unknown';
  const appMediaId = utagScriptElement.dataset[DATA_ATTR_MEDIAID] || 0;
  const loginStatus = utagScriptElement.dataset[DATA_ATTR_LOGIN_STATUS];

  window.utag_data = {
    'app_server': 'signin-ui',
    'app_medienid': appMediaId,
    'app_version': appVersion,
    'customer_status': 'nicht_eingeloggt'
  };

  if (loginStatus) {
    window.utag_data.event_label = loginStatus;
    window.utag_data.event_category = 'Fehlermeldung';
    window.utag_data.event_action = window.utag_data.app_server;
  }

  initUtag(utagSrcUrl);
}, false);
